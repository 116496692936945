@import "src/assets/scss/variables";
@import "src/assets/scss/mixins";

.App {
	padding-top: $header_height;
	background-color: map-get($system, fullWhite);

	.loader-container {
		height: calc(100vh - #{$header_height});
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&.freezed {
		padding-bottom: 3.5rem;
	}
}

.metro-motion {
	overflow: hidden;
	height: 0;
	opacity: 0;
}
