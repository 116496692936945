@import "src/assets/scss/variables";
@import "src/assets/scss/mixins";

.payment {
	&-option {
		padding: 2rem 1.5rem;

		@include media-query-gt-md {
			padding: 2rem;
		}

		.sky-capital {
			color: map-get($system, darkGray);
			margin-bottom: 16px;
		}
		&-wrapper {
			display: flex;
			align-items: flex-start;
			flex-direction: column;
			@include media-query-gt-md {
				flex-direction: row;
				align-items: center;
			}
			img {
				width: 32px;
				height: 32px;
				margin-bottom: 14px;
				@include media-query-gt-md {
					margin-bottom: 0;
					width: 56px;
					height: 56px;
				}
			}
		}
		&-content {
			width: 100%;
			display: grid;
			grid-gap: 2rem;
			grid-template-columns: 1fr;

			@include media-query-gt-md {
				margin: 0 auto 0 50px;
				max-width: 85%;
				grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
			}

			&-section {
				color: map-get($system, grayText);
				.medium {
					margin-bottom: 0.5rem;
					color: map-get($system, darkGray);
				}
			}
		}
	}

	&-option-border {
		border-bottom: dashed 2px map-get($blacks, 100);
	}
}
