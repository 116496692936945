.normal {
	font-weight: normal;
}

strong,
.medium {
	font-weight: 500;
}

.bold {
	font-weight: 700;
}

.italic {
	font-style: italic;
}

.uppercase {
	text-transform: uppercase;
}

.blue {
	color: map-get($system, blue);
}

.sky {
	&-mob {
		display: block;
		@include media-query-gt-md {
			display: none;
		}
	}

	&-desk {
		display: none;
		@include media-query-gt-md {
			display: block;
		}
	}
}
