@import "src/assets/scss/mixins";
@import "src/assets/scss/variables";

.button {
	position: relative;
	overflow: hidden;
	border: 0;
	margin: 0;
	display: flex;
	width: 100%;
	max-width: 18rem;
	min-width: 10rem;
	min-height: 43px;
	background-color: map-get($system, blue);
	color: map-get($blacks, 0);
	font-family: $main-font;
	font-weight: 500;
	border-radius: $border-radius;
	font-size: 20px;
	line-height: 43px;
	letter-spacing: normal;
	word-spacing: normal;
	text-align: center;
	text-decoration: none;
	text-overflow: ellipsis;
	white-space: nowrap;
	cursor: pointer;
	user-select: none;
	transition: all 0.3s ease-in;
	justify-content: center;

	&:after {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		display: block;
		width: 100%;
		height: 50%;
		background: linear-gradient(180deg, transparent, rgba(255, 255, 255, 0.25));
		transition:
			background-color 0.3s ease-out,
			transform 0.4s cubic-bezier(0.91, 0.09, 0.63, 0.9);
	}

	&.isSign,
	&.green {
		&:before {
			content: "";
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			width: 0;
			background-color: #2fa841;
			transition: width 2s ease-in-out;
		}

		&.signing:before,
		&.green:before {
			width: 100%;
		}
	}

	p {
		z-index: 1;
	}

	&:hover {
		@include media-query-gt-md {
			background-color: rgba(0, 15, 190, 1);
		}
	}

	&:disabled,
	&.disabled {
		cursor: not-allowed;
		background: linear-gradient(179deg, #909090 0, #a7a7a7 49.5%, #999 49.67%, #999 100%);
		border-color: map-get($blacks, 400);
		user-select: none;
		pointer-events: none;
	}

	&.green {
		background: #2fa841;
	}

	&_icon {
		position: absolute;
		right: 1rem;
		max-width: 1rem;
	}
}
