@import "src/assets/scss/mixins";
@import "src/assets/scss/variables";

.typ {
	display: flex;
	flex-direction: column;
	align-items: center;

	&_icon {
		width: 48px;
		height: 48px;
		margin: 1.5rem 0 1rem;
		@include media-query-gt-sm {
			margin: 5rem 0 0.938rem;
		}
	}
	&_title {
		@include gradientify();
		text-align: center;
		line-height: 1.2;
		font-weight: 500;
		font-size: 1.5rem;
		@include media-query-gt-sm {
			font-size: 1.9rem;
		}
	}
	&_description {
		text-align: center;
		font-size: 1.125rem;
		line-height: 1.25rem;
		color: $text;
		margin: 1rem 1rem 0;
		@include media-query-gt-sm {
			margin: 1.313rem 0 0;
		}
	}
}
