@import "src/assets/scss/variables";
@import "src/assets/scss/mixins";

.error-page {
	background-color: map-get($system, fullWhite);
	height: calc(100vh - 60px);
	display: flex;
	justify-content: center;
	padding: 24px;
	@include media-query-gt-md {
		padding: 80px 24px;
	}
	.error-page-content {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		flex-direction: column;
		&-icon {
			width: 48px;
			margin-bottom: 4px;
		}
		p,
		h1 {
			text-align: center;
			margin-top: 16px;
			color: $text;
		}
		@include media-query-gt-md {
			p {
				padding-left: 20%;
				padding-right: 20%;
				width: 100%;
				word-wrap: break-word;
			}
		}
	}
}
