* {
	box-sizing: border-box;
}

body {
	position: relative;
	overflow-x: hidden;
	padding: 0;
	margin: 0;
	font-family: $main-font;
	font-weight: 400;
}

p,
h1 {
	margin: 0;
}

svg {
	width: 100%;
	height: auto;
}
