@import "src/assets/scss/mixins";
@import "src/assets/scss/variables";

.offers-list {
	&__title {
		margin-bottom: 2rem;
		padding: 1rem;
		font-size: 1.1rem;
		text-align: center;
		background: map-get($gradient, system-1);
		color: map-get($blacks, 0);
		border-top-left-radius: $border-radius;
		border-top-right-radius: $border-radius;

		@include media-query-gt-md {
			margin: 2rem;
			font-size: 1.6rem;
			border-radius: $border-radius;
		}
	}

	&__wrp {
		padding: 0 1.5rem 1.5rem;

		@include media-query-gt-md {
			margin: 0 auto;
			padding: 0 2rem 6rem 2rem;
			max-width: 85%;
		}
	}
}

.offer {
	padding: 1.5rem;
	padding-bottom: 0;

	&__heading {
		display: flex;
		align-items: center;
		justify-content: space-between;

		&--type {
			font-size: 0.9rem;
			color: map-get($blacks, 450);

			@include media-query-gt-sm {
				font-size: 1.1rem;
			}
		}

		&--details {
			font-size: 0.9rem;
			text-align: right;
			color: map-get($system, blue);
			cursor: pointer;

			@include media-query-gt-sm {
				font-size: 1rem;
				text-align: initial;
			}
		}
	}

	&__title {
		margin-top: 1.5rem;
		margin-bottom: 0.5rem;
		font-size: 0.9rem;
		letter-spacing: 1px;
		color: map-get($blacks, 450);
	}

	&__divider {
		margin-top: 1.5rem;
		margin-bottom: 0;
		width: 100%;
		display: block;
		border-top: 1px solid map-get($blacks, 100);
	}
}

.offerDetails {
	margin-top: 0.875rem;
	margin-bottom: 0.625rem;
	display: flex;
	flex-direction: column;
	align-items: stretch;
	color: map-get($system, grayText);

	@include media-query-gt-md {
		flex-direction: row;
		gap: 1rem;
		margin-top: 1.5rem;
		margin-bottom: 0;
	}

	&__wrp {
		width: 100%;
		@include media-query-gt-md {
			max-width: 65%;
		}
	}

	&__title {
		margin-bottom: 0.5rem;
		width: 100%;

		@include media-query-gt-md {
			max-width: 35%;
			margin-bottom: 0;
		}
	}

	&__firstline {
		line-height: 1.25;
	}

	&__secondline {
		margin-top: 0.5rem;
		font-size: 14px;
		font-weight: 300;
		color: map-get($system, grayText);

		a {
			font-weight: 500;
			text-decoration: none;
			color: map-get($system, blue);
		}
	}
}
