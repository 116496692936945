@import "src/assets/scss/mixins";
@import "src/assets/scss/variables";

.cost {
	padding: 0.6rem 0;
	display: flex;
	justify-content: space-between;
	align-items: stretch;
	flex-direction: column;

	@include media-query-gt-xxs {
		flex-direction: unset;
	}

	@include media-query-gt-sm {
		padding: 1rem 0;
		align-items: center;
	}

	&__details {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: center;

		@include media-query-gt-sm {
			flex-direction: row;
			align-items: center;
			justify-content: flex-start;
		}
	}

	&__label {
		color: map-get($system, darkGray);
	}

	&__price {
		padding: 0.4rem;
		font-size: 0.9rem;
		flex: 1 1 100%;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		text-align: right;
		border-radius: $border-radius;
		align-self: flex-end;
		max-width: fit-content;
		min-width: 3.7rem;
		height: fit-content;
		min-height: 2.1rem;

		@include media-query-gt-sm {
			padding: 0.6rem;
			min-width: 4.5rem;
			font-size: 1rem;
		}

		&.oneShot {
			color: map-get($brand, lightPurple);
			background-color: rgba(map-get($brand, lightPurple), 0.1);
		}

		&.recurring {
			color: map-get($system, blue);
			background-color: rgba(map-get($system, blue), 0.1);
		}

		&.financial {
			color: map-get($system, orange);
			background-color: map-get($system, orangeBackground);
		}
	}

	&__recurring {
		margin-top: 0.2rem;
		width: 100%;
		color: map-get($system, grayText);

		span {
			font-size: 0.75rem;
			line-height: 1rem;
		}

		@include media-query-gt-sm {
			margin-top: 0;
			margin-left: auto;
			padding-right: 1rem;
			width: auto;
			display: flex;
			flex-direction: column;
			font-size: 0.9rem;
			text-align: right;
		}
	}

	&__validitylabel {
		margin-left: 0.2rem;

		@include media-query-gt-sm {
			margin-left: auto;
		}
	}
}

.oneShot-title {
	color: map-get($system, titlePurple);
	font-weight: 500;
}

.financial-title {
	color: map-get($system, orange);
	font-weight: 500;
}

.recurring-title {
	color: map-get($system, blue);
	font-weight: 500;
}
