@import "src/assets/scss/variables";
@import "src/assets/scss/mixins";

.recapPage {
	padding-bottom: 2rem;
	background-color: map-get($system, fullWhite);

	&__content {
		padding: 16px 24px;

		@include media-query-gt-md {
			padding: 32px 80px;
		}
	}

	&__anchor {
		display: flex;
		justify-content: center;

		&--icon {
			max-width: 2.5rem;
			cursor: pointer;
			transition: all 0.3s ease-in-out;

			@include media-query-gt-md {
				&:hover {
					opacity: 0.65;
					transform: translateY(3px);
				}
			}
		}
	}

	&__buttonWrp {
		display: flex;
		justify-content: center;

		.button {
			max-width: unset;

			@include media-query-gt-sm {
				max-width: 18rem;
			}
		}
	}
}

.recap-heading {
	&-wrp {
		position: relative;
		margin-bottom: 2.5rem;
		width: 100%;
		height: 27.5vh;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		background: map-get($gradient, system-1);
		color: map-get($system, grayText);

		@include media-query-gt-sm {
			height: 35vh;
		}

		@include media-query-gt-md {
			margin-bottom: 6rem;
			height: 40vh;
		}

		@include media-query-gt-lg {
			height: 35vh;
		}

		h1 {
			margin: 0;
			text-align: center;
			color: map-get($blacks, 0);
			line-height: 1.2;
		}
	}

	&-informative {
		position: relative;
		margin: -5rem auto 2rem;
		padding: 1.5rem;
		width: 100%;
		max-width: 90vw;
		border-radius: $border-radius;
		background-color: map-get($blacks, 0);
		box-shadow: $box-shadow;

		@include media-query-gt-sm {
			max-width: 70vw;
		}

		@include media-query-gt-md {
			position: absolute;
			bottom: 0;
			left: 50%;
			transform: translate(-50%, 50%);
			margin: 0;
			padding: 2rem;
			max-width: 65vw;
		}

		@include media-query-gt-lg {
			max-width: 60vw;
		}

		@include media-query-gt-xl {
			max-width: 55vw;
		}

		&--text {
			margin: 0;
			line-height: 1.4;
			text-align: center;

			@include media-query-gt-md {
				text-align: left;
			}
		}
	}
}

.paymentLabels {
	&__wrp {
		position: relative;
		margin-bottom: 2rem;
		padding: 1.125rem 0.875rem 1rem 1.125rem;
		background-color: map-get($blacks, 100);
		border-radius: $border-radius;

		@include media-query-gt-md {
			margin-bottom: 5rem;
			padding: 1.2rem;
		}

		&.flex-wrp {
			display: flex;
		}
	}

	&__contentWrp {
		padding-left: 0.625rem;
		width: 100%;
		margin: auto;
		display: flex;
		flex-direction: column;
	}

	&__info {
		width: 1.25rem;
	}

	&__title {
		font-size: 0.875rem;
		padding-bottom: 0.5rem;
		color: map-get($system, #a9a9a9);

		@include media-query-gt-md {
			padding: 0.2rem;
		}
	}

	&__description {
		margin-top: 0.25rem;
		font-size: 0.875rem;
		color: map-get($system, grayText);
	}
}
