@import "src/assets/scss/mixins";
@import "src/assets/scss/variables";

.accordion {
	&-container-detailoffer:not(:last-child) {
		border-bottom: 1px solid rgba(242, 242, 242, 1);
		padding-bottom: 1rem;
	}

	&-container-cards {
		border-bottom: 1px solid rgba(242, 242, 242, 1);
	}

	&-header {
		background-color: transparent;
		padding-top: 2em;
		font-size: 1.2rem;
		color: gray;
		cursor: pointer;
		display: flex;
		align-items: center;

		@include media-query-gt-sm {
			padding-bottom: 0.5em;
		}
	}

	&-header-padding {
		padding: 2em;
	}

	&-title {
		flex-grow: 1;
	}

	&-content {
		background-color: transparent;
	}

	&-icon {
		margin-left: 5px;
		width: 1.2rem;
	}

	&-title-offers {
		flex-grow: 1;
		height: 18px;
		width: 279px;
		color: #4d4d4d;
		font-size: 14px;
		letter-spacing: 1px;
		line-height: 18px;
	}

	&-rotate-svg {
		transform: rotate(180deg);
	}
}

.offerDetails {
	display: flex;
	flex-direction: column;
	align-items: stretch;
	color: map-get($system, grayText);
	padding: 10px 10px 10px 0;
	font-size: 16px;

	@include media-query-gt-md {
		flex-direction: row;
		gap: 1rem;
		padding: 10px 10px 10px 0;
	}

	&__wrp {
		width: 100%;
		@include media-query-gt-md {
			max-width: 65%;
		}
	}

	&__title {
		margin-bottom: 0.5rem;
		width: 100%;
		font-size: 16px;
		font-weight: 500;
		letter-spacing: 0;
		line-height: 20px;

		@include media-query-gt-md {
			max-width: 35%;
			margin-bottom: 0;
		}
	}

	&__firstline {
		line-height: 1.25;
	}

	&__secondline {
		margin-top: 0.5rem;
		font-size: 14px;
		font-weight: 300;
		color: map-get($system, grayText);

		a {
			font-weight: 500;
			text-decoration: none;
			color: map-get($system, blue);
		}
	}

	&__content {
		margin-left: 0;
	}
}
