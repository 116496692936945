@font-face {
	font-display: swap;
	font-family: "SkyText";
	font-style: normal;
	font-weight: 400;
	src:
		url("https://cache.sky.it/customer-fonts/SkyText-Regular.woff2") format("woff2"),
		url("https://cache.sky.it/customer-fonts/SkyText-Regular.woff") format("woff");
}

@font-face {
	font-display: swap;
	font-family: "SkyText";
	font-style: italic;
	font-weight: 400;
	src:
		url("https://cache.sky.it/customer-fonts/SkyText-Italic.woff2") format("woff2"),
		url("https://cache.sky.it/customer-fonts/SkyText-Italic.woff") format("woff");
}

@font-face {
	font-display: swap;
	font-family: "SkyText";
	font-style: normal;
	font-weight: 500;
	src:
		url("https://cache.sky.it/customer-fonts/SkyTextMedium-Regular.woff2") format("woff2"),
		url("https://cache.sky.it/customer-fonts/SkyTextMedium-Regular.woff") format("woff");
}

@font-face {
	font-display: swap;
	font-family: "SkyText";
	font-style: normal;
	font-weight: 700;
	src:
		url("https://cache.sky.it/customer-fonts/SkyText-Bold.woff2") format("woff2"),
		url("https://cache.sky.it/customer-fonts/SkyText-Bold.woff") format("woff");
}
