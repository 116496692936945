@import "src/assets/scss/variables";
@import "src/assets/scss/mixins";

.header {
	position: fixed;
	z-index: 100;
	top: 0;
	width: 100%;
	height: $header_height;
	padding: 0 24px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	background-color: map-get($blacks, 0);
	box-shadow: $box-shadow;

	@include media-query-gt-md {
		padding: 0 80px;
	}

	p {
		text-align: right;
		color: map-get($system, grayText);
	}

	span {
		display: none;

		@include media-query-gt-md {
			display: inline;
		}
	}

	.sky-logo {
		cursor: pointer;
		width: 100%;
		max-width: 60px;
	}
}
