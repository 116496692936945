@import "src/assets/scss/variables";
@import "src/assets/scss/mixins";

.panel {
	margin-bottom: 3.5rem;
}

.panel-paymentMethodsSection {
	margin-bottom: 2rem;
}

.panel-header {
	margin-bottom: 0.625rem;

	@include media-query-gt-md {
		margin-bottom: 1rem;
	}

	&-title {
		margin-bottom: 0.25rem;
		font-size: 1.6rem;
		color: map-get($blacks, 450);
	}
	&-subtitle {
		margin-bottom: 0.25rem;
		font-size: 0.875rem;
		color: map-get($blacks, 450);
	}
}

.card {
	padding-top: 0.01rem;
	border: 1px solid map-get($blacks, 100);
	border-radius: $border-radius;
	background-color: map-get($blacks, 0);
	box-shadow: $box-shadow;

	&-title {
		padding: 16px;
	}
}

.cta {
	margin-bottom: 0.625rem;
	display: flex;
	justify-content: space-between;
	align-items: center;

	@include media-query-gt-md {
		margin-bottom: 1rem;
	}

	&-title {
		margin-bottom: 0.25rem;
		font-size: 1.6rem;
		color: map-get($blacks, 450);
	}
	&-subtitle {
		margin-bottom: 0.25rem;
		font-size: 0.875rem;
		color: map-get($blacks, 450);
	}
	&-detail {
		color: map-get($system, blue);
		font-size: 18px;
		margin-top: 2rem;
		font-weight: 500;
		letter-spacing: 0;
		line-height: 18px;
		text-align: right;
		cursor: pointer;
	}

	@include media-query-gt-xs {
		.panel-header-detail {
			line-height: 21px;
			font-size: 18px;
		}
	}
}
