@import "src/assets/scss/mixins";
@import "src/assets/scss/variables";

.offer {
	padding: 1.5rem;
	padding-bottom: 0;

	&__heading {
		display: flex;
		align-items: center;
		justify-content: space-between;

		&--type {
			font-size: 0.9rem;
			color: map-get($blacks, 450);

			@include media-query-gt-sm {
				font-size: 1.1rem;
			}
		}

		&--details {
			font-size: 0.9rem;
			text-align: right;
			color: map-get($system, blue);
			cursor: pointer;

			@include media-query-gt-sm {
				font-size: 1rem;
				text-align: initial;
			}
		}
	}

	&__wrp {
		padding: 0 1.5rem 1.5rem;

		@include media-query-gt-md {
			margin: 0 auto;
			padding: 0 2rem 2rem 2rem;
			max-width: 85%;
		}
	}

	&__title {
		margin-top: 1.5rem;
		margin-bottom: 0.5rem;
		font-size: 1rem;
		color: map-get($blacks, 450);

		@include media-query-gt-md {
			font-size: 1.25rem;
		}
	}

	&__divider {
		margin-top: 1.5rem;
		margin-bottom: 0;
		width: 100%;
		display: block;
		border-top: 1px solid map-get($blacks, 100);
	}
}
