@import "src/assets/scss/mixins";
@import "src/assets/scss/variables";

.drawer {
	&__overlay {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		width: 100vw;
		height: 100vh;
		opacity: 1;
		background-color: rgba(map-get($blacks, 700), 0.7);
		pointer-events: all;
		z-index: 998;

		&.drawer__invisible {
			opacity: 0;
			z-index: -1;
			pointer-events: none;
		}
	}

	&__wrp {
		overflow-y: auto;
		position: absolute;
		z-index: 999;
		padding-bottom: 2rem;
		width: 100vw;
		height: 100vh;
		pointer-events: all;
		background-color: map-get($blacks, 0);
		box-shadow: $box-shadow;

		@include media-query-gt-sm {
			width: 50vw;
			height: 100vh;
		}

		@include media-query-gt-xl {
			width: 42.5vw;
		}

		&.drawer__invisible {
			z-index: -1;
			pointer-events: none;
		}
	}

	&__nav {
		position: sticky;
		top: 0;
		z-index: 999;
		padding: 1.5rem;
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-bottom: 1px solid map-get($blacks, 100);
		background-color: map-get($blacks, 0);

		@include media-query-gt-sm {
			padding: 2rem 1.5rem;
		}

		@include media-query-gt-lg {
			padding: 2rem;
		}

		&--title {
			margin: 0;
			font-size: 1.2rem;
			font-weight: 500;
			color: map-get($blacks, 450);

			@include media-query-gt-sm {
				font-size: 1.4rem;
			}
		}
	}

	&__control {
		display: flex;
		align-items: center;
		cursor: pointer;

		&--icon {
			max-width: 1.25rem;

			@include media-query-gt-sm {
				max-width: 1.5rem;
			}
		}

		&--text {
			margin-left: 0.3rem;
			font-weight: 500;

			@include media-query-gt-sm {
				margin-left: 0.4rem;
			}
		}
	}

	&__content {
		padding: 0 1.5rem;
		display: grid;
		grid-template-columns: 1fr;
		grid-gap: 2rem 1.5rem;

		@include media-query-gt-md {
			grid-template-columns: repeat(2, 1fr);
		}

		@include media-query-gt-lg {
			padding: 0 2rem;
		}
	}

	&__box {
		&--wrp {
			position: relative;
			margin-bottom: 4rem;
			min-height: 14rem;
			border-radius: 0.25rem;
			display: flex;
			align-items: center;
			flex-direction: column;
			background: linear-gradient(
				180deg,
				map-get($system, blue) 0%,
				map-get($system, lightBlue) 100%
			);
			font-size: 1.4rem;
			color: map-get($blacks, 0);
		}
		&--txt {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: space-around;
			min-height: 6.75rem;
			padding: 0 1rem;
			margin-top: 0.85rem;
		}

		&--title {
			font-weight: 500;
			margin: auto 0px;
			text-align: center;
		}

		&--img {
			position: absolute;
			bottom: 0;
			transform: translateY(33%);
			max-width: 85%;
		}
	}

	&__list {
		padding-inline-start: 1rem;
		padding-inline-end: 1rem;
		list-style-type: none;
		display: flex;
		flex-direction: column;
		align-items: flex-start;

		@include media-query-gt-lg {
			padding-inline-start: 2rem;
			padding-inline-end: 2rem;
		}

		&--item {
			margin: 0.5rem 0;
			display: flex;
			align-items: flex-start;
			color: map-get($blacks, 450);
		}

		&--icon {
			max-width: 17.17px;
			max-height: 12.75px;
			margin-right: 0.6rem;
			margin-top: 0.15rem;
		}
	}
}
