@import "src/assets/scss/variables";
@import "src/assets/scss/mixins";

.stepper {
	display: flex;
	align-items: center;
	z-index: 100;
	background-color: map-get($system, fullWhite);
	padding: 0 24px;
	overflow: hidden;
	flex: 1;
	height: 50px;
	@include media-query-gt-sm {
		height: 76px;
		justify-content: center;
		align-items: flex-start;
		padding: 16px 24px;
	}
	.step-wrapper {
		display: flex;
		align-items: center;

		&:not(.selected) {
			flex: 1;
		}

		@include media-query-gt-sm {
			&:not(.selected) {
				flex: unset;
			}
			& {
				align-items: normal;
			}
		}
		.step {
			display: flex;
			flex-direction: row;
			align-items: center;
			-webkit-tap-highlight-color: transparent;
			width: auto;
			cursor: pointer;
			outline-width: 0;
			outline-color: transparent;
			outline-style: unset;

			@include media-query-gt-sm {
				flex-direction: column;
				width: 60px;
			}

			.step-icon {
				border-radius: 22px;
				height: 28px;
				width: 28px;
				display: flex;
				align-items: center;
				justify-content: center;
				color: map-get($blacks, 0);
				transition: all 0.25s ease-in;
				border: 2px solid transparent;
				margin: 0 4px;
				@include media-query-gt-sm {
					margin: 0;
				}

				p {
					height: 20px;
					width: 20px;
					border-radius: 18px;
					display: flex;
					align-items: center;
					justify-content: center;
					background-color: #cccccc;
					transition: all 0.25s ease-in;
					&.checked {
						transform: rotate(45deg) scale(-1, 1);
					}
				}

				&.selected {
					p {
						background-color: #000ff5;
					}
				}

				&.current {
					border-color: #000ff5;
				}
			}

			.step-label {
				font-size: 14px;
				color: #cccccc;
				transition: all 0.1s ease-in;
				text-align: center;
				display: none;
				opacity: 0;
				width: max-content;
				overflow: hidden;
				padding-right: 8px;

				@include media-query-gt-sm {
					display: block;
					margin-top: 8px;
					padding-right: 0;
					opacity: 1;
				}

				&.selected {
					color: #000ff5;
				}
				&.current {
					display: block;
					opacity: 1;
				}
			}

			&.done {
				.step-icon {
					mat-icon {
						background-color: map-get($system, blue);
					}
				}
			}

			&.selected {
				.step {
					padding: 2px 6px;
				}

				.step-label {
					color: map-get($blacks, 700);
				}

				.step-icon {
					border-color: map-get($system, blue);
				}
			}
		}

		.step-line {
			height: 1px;
			display: flex;
			flex: 1;
			background-color: #d8d8d8;
			position: relative;
			transition: all 0.25s ease-in;
			@include media-query-gt-sm {
				margin-top: 12px;
				width: 100px;
			}

			.step-line-content {
				position: absolute;
				height: 1px;
				transition: all 0.25s ease-in;
				background-color: #000ff5;
				width: 0;
				&.selected {
					width: 100%;
				}
			}
		}
		&.selected {
			.step-line {
				min-width: 50px;
				.step-line-content {
					width: 100%;
				}
				@include media-query-gt-sm {
					width: 100px;
				}
			}
		}
	}
}
