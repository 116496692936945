@import "src/assets/scss/variables";
@import "src/assets/scss/mixins";

.done {
	padding: 16px 24px 74px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	background-color: #fff;
}
