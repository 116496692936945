@import "src/assets/scss/mixins";
@import "src/assets/scss/variables";

.acceptance {
	margin-bottom: 2.5rem;
	opacity: 0.2;
	pointer-events: none;
	user-select: none;
	transition: all 0.3s ease-out;
	cursor: not-allowed;

	@include media-query-gt-md {
		margin-bottom: 3.5rem;
	}

	&.unlocked {
		opacity: 1;
		pointer-events: all;
		user-select: text;
		cursor: default;
	}

	&__panel {
		margin-bottom: 2rem;
		color: map-get($system, grayText);

		@include media-query-gt-md {
			margin-bottom: 3.75rem;
		}

		.panel-header-title {
			font-size: 1.4rem;

			@include media-query-gt-md {
				font-size: 1.6rem;
			}
		}

		&--text {
			line-height: 1.4;
		}

		&--link {
			margin-top: 1rem;
			margin-right: 2.25rem;
			display: inline-block;
			text-decoration: none;

			&:only-child {
				margin: 0;
			}
		}
	}

	&__button {
		margin: 0 auto 2rem;
		max-width: unset;

		&.green {
			cursor: not-allowed;
		}

		@include media-query-gt-sm {
			max-width: 18rem;
		}

		@include media-query-gt-md {
			margin: 0 auto 3.75rem;
		}
	}

	.card {
		padding: 1.5rem;

		@include media-query-gt-md {
			padding: 2rem;
		}
	}
}
