@import "src/assets/scss/mixins";
@import "src/assets/scss/variables";

.checkbox {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	max-width: 1.2rem;
	max-height: 1.2rem;
	padding: 0.6rem;
	background-color: map-get($blacks, 0);
	border: 1px solid map-get($blacks, 200);
	border-radius: $border-radius;
	color: map-get($blacks, 0);
	transition: all 0.2s ease-in;

	&.error {
		border-color: map-get($system, red);
	}

	&:hover {
		border: 1px solid map-get($system, blue);
		cursor: pointer;
	}

	&:focus {
		border: 1px solid map-get($system, blue);
		outline: 3px solid rgba(map-get($system, blue), 0.3);
	}

	&.checked {
		background-color: map-get($system, blue);

		&:focus {
			outline: none;
		}
	}

	&.disabled {
		background-color: map-get($blacks, 200);

		&:hover {
			border-color: map-get($blacks, 200);
			cursor: not-allowed;
		}

		&:focus {
			outline: none;
			border-color: map-get($blacks, 200);
		}
	}

	&__wrp {
		margin-bottom: 1rem;
		width: 100%;
		display: flex;
		flex-direction: row-reverse;
		justify-content: flex-end;
		align-items: flex-start;
	}

	&__hidden {
		display: none;
		overflow: hidden;
		position: absolute;
		margin: -1px;
		padding: 0;
		width: 1px;
		height: 1px;
		clip: rect(0 0 0 0);
		clip-path: inset(50%);
		border: 0;
		white-space: nowrap;
	}

	&__inner {
		margin-left: 0.7rem;
		transition: opacity 0.2s ease-in;
		cursor: pointer;

		&.disabled {
			opacity: 0.5;
			cursor: not-allowed;
		}

		&:hover {
			&:not(.disabled) {
				~ .checkbox {
					border: 1px solid map-get($system, blue) !important;
				}
			}
		}
	}

	&__icon {
		position: absolute;
		max-width: 0.75rem;
		max-height: 0.75rem;
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}

.error_label {
	color: map-get($system, red);
	font-size: 0.875rem;
	line-height: 1.25rem;
}
