@import "./colors";
@import "./variables";

@mixin media-query-gt-xxs() {
	@media screen and (min-width: #{map-get($breakpoints, xxs) + 1}px) {
		@content;
	}
}

@mixin media-query-gt-xs() {
	@media screen and (min-width: #{map-get($breakpoints, xs) + 1}px) {
		@content;
	}
}

@mixin media-query-gt-sm() {
	@media screen and (min-width: #{map-get($breakpoints, sm) + 1}px) {
		@content;
	}
}

@mixin media-query-gt-md() {
	@media screen and (min-width: #{map-get($breakpoints, md) + 1}px) {
		@content;
	}
}

@mixin media-query-gt-lg() {
	@media screen and (min-width: #{map-get($breakpoints, lg) + 1}px) {
		@content;
	}
}

@mixin media-query-gt-xl() {
	@media screen and (min-width: #{map-get($breakpoints, xl) + 1}px) {
		@content;
	}
}

@mixin gradientify() {
	margin: 0;
	width: fit-content;
	font-family: $main-font;
	font-weight: 100;
	letter-spacing: normal;
	background: map-get($gradient, system-1);
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	white-space: wrap;
}
