@import "src/assets/scss/mixins";
@import "src/assets/scss/variables";

.ready-to-sign {
	display: flex;
	align-items: center;
	flex-direction: column;

	.icon {
		position: relative;
		margin: 0 auto;
		width: 32px;
		height: 32px;
		border-radius: 50%;
		background-color: map-get($system, blue);

		i {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			width: 50%;
		}
	}

	&__title {
		@include gradientify();
		font-size: 1.9rem;
		line-height: 1.2;
		font-weight: 500;
		margin: 5.5rem 0 0;
		text-align: center;

		@include media-query-gt-xs {
			margin-bottom: 2rem;
			font-size: 2.25rem;
		}
	}

	&__accepted-consents {
		margin: 1.25rem 0;
		font-size: 1.125rem;
		line-height: 1.25rem;
		color: $text;
		text-align: center;
	}

	&__disclaimer {
		font-size: 0.875rem;
		line-height: 1.125rem;
		max-width: 36rem;
		margin: 1.25rem 0;
		color: $text;
		text-align: center;
	}

	.RecapPoint {
		&:first-of-type {
			margin-top: 2.5rem;
		}
	}
}
