.sky {
	&-capital {
		font-size: 14px;
		text-transform: uppercase;
	}
	&-caption {
		font-size: 14px;
	}

	&-body-small {
		font-size: 12px;
	}
	&-body {
		font-size: 16px;
	}
	&-title {
		font-size: 18px;
		@include media-query-gt-md {
			font-size: 20px;
		}
	}
	&-section-title {
		font-size: 20px;
		@include media-query-gt-md {
			font-size: 24px;
		}
	}
	&-page-title {
		font-size: 24px;
		@include media-query-gt-md {
			font-size: 34px;
		}
	}
	&-display-standard {
		font-size: 28px;
		@include media-query-gt-md {
			font-size: 38px;
		}
	}
	&-display-extra-large {
		font-size: 34px;
		@include media-query-gt-md {
			font-size: 54px;
		}
	}
}
