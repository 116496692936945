@import "src/assets/scss/mixins";
@import "src/assets/scss/variables";

.footer {
	overflow: hidden;
	position: fixed;
	bottom: 0;
	left: 0;
	padding: 0 24px;
	width: 100%;
	height: 5.5rem;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: map-get($blacks, 0);
	border-top: 1px solid map-get($blacks, 100);
	box-shadow: $footer-box-shadow;
	z-index: 99;

	@include media-query-gt-md {
		padding: 0 80px;
		justify-content: space-between;
	}

	&__icon {
		width: 100%;
		max-width: 1.5rem;

		@include media-query-gt-md {
			margin-right: 1.5rem;
			max-width: 2.5rem;
		}
	}

	&__text {
		display: flex;
		align-items: center;
		flex-direction: column;
		text-align: center;

		@include media-query-gt-md {
			flex-direction: row;
			text-align: left;
		}

		&--title {
			font-size: 0.9rem;
			line-height: 1.4;

			@include media-query-gt-md {
				font-size: 1.1rem;
			}
		}
		&--link {
			text-decoration: none;
		}
	}

	&__navigation {
		display: flex;
		align-items: center;

		&-control {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			padding: 0.5rem 0.7rem;
			display: flex;
			align-items: center;
			background-color: map-get($blacks, 0);
			border-radius: 50%;
			box-shadow: $box-shadow;

			@include media-query-gt-md {
				position: inherit;
				top: unset;
				transform: unset;
				padding: 0;
				background-color: transparent;
				border-radius: 0;
				box-shadow: none;
			}

			.chevron {
				width: 100%;
				max-width: 0.75rem;
			}

			&.disabled {
				color: map-get($blacks, 200);
				opacity: 0.5;
				pointer-events: none;
				cursor: not-allowed;

				.chevron {
					path {
						fill: map-get($blacks, 200);
					}
				}
			}

			&:hover {
				cursor: pointer;
			}

			&.previous {
				left: 5%;

				span {
					display: none;
				}

				.icon-previous {
					transform: rotate(90deg);
				}

				@include media-query-gt-md {
					left: unset;
					transition: transform 0.2s ease-out;

					span {
						display: block;
						margin-left: 0.4rem;
					}

					&:hover {
						transform: translateX(-5%);
					}
				}
			}

			&.next {
				right: 5%;

				span {
					display: none;
				}

				.icon-next {
					transform: rotate(-90deg);
				}

				@include media-query-gt-md {
					right: unset;
					margin-left: 3rem;
					transition: transform 0.2s ease-out;

					span {
						display: block;
						margin-right: 0.4rem;
					}

					&:hover {
						transform: translateX(5%);
					}
				}
			}
		}
	}
}
