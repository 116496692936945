@import "src/assets/scss/mixins";
@import "src/assets/scss/variables";

.privacy {
	opacity: 0.2;
	pointer-events: none;
	user-select: none;
	transition: all 0.3s ease-out;
	cursor: not-allowed;

	&.unlocked {
		opacity: 1;
		pointer-events: all;
		user-select: text;
		cursor: default;
	}

	&__title {
		font-size: 1.4rem;
		color: map-get($system, grayText);
		margin-bottom: 1rem;

		@include media-query-gt-md {
			font-size: 1.6rem;
		}
	}

	&__description {
		margin: 1.25rem 0 0;
		line-height: 1.4;
	}

	&__button {
		margin: 0 auto 2rem;
		max-width: unset;

		@include media-query-gt-sm {
			max-width: 18rem;
		}

		@include media-query-gt-md {
			margin: 0 auto 4rem;
		}
	}

	&__link {
		margin-top: 0.75rem;
		margin-right: 2.25rem;
		display: inline-block;
		text-decoration: none;

		&.error {
			margin-top: 0.25rem;
		}

		&:only-child {
			margin: 0;
		}
	}

	&__item {
		&:not(:last-child) {
			margin-bottom: 1.2rem;
			display: inherit;
		}

		&--title {
			font-size: 1rem;
			line-height: 1.25rem;
			font-weight: 500;
			color: map-get($system, grayText);
		}

		&--description {
			font-size: 0.875rem;
			line-height: 1.125rem;
			font-weight: 300;
			color: map-get($system, lightGray);
		}
	}

	&__mandatory,
	&__optional {
		padding: 1.5rem;

		@include media-query-gt-md {
			padding: 2rem;
		}
	}

	&__optional {
		&--description {
			margin-bottom: 1rem;
			line-height: 1.4;
		}
	}

	&__divider {
		height: 0.05rem;
		width: 100%;
		display: block;
		background: map-get($blacks, 200);

		&.inside {
			margin: 1.4rem 0 1.5rem;
		}
	}

	.checkbox__wrp {
		&:first-child {
			margin-bottom: 0.3rem;
		}
	}
}
