@import "src/assets/scss/mixins";
@import "src/assets/scss/variables";

.consents {
	&__title {
		@include gradientify();
		font-size: 1.9rem;
		line-height: 1.2;
		font-weight: 500;
		margin-bottom: 0.5rem;

		&.disabled {
			opacity: 0.2;
		}

		@include media-query-gt-md {
			margin-bottom: 0.875rem;
			font-size: 2.25rem;
		}
	}
}
