@import "src/assets/scss/variables";
@import "src/assets/scss/mixins";

.cga {
	padding: 16px 24px;
	background-color: map-get($system, fullWhite);

	@include media-query-gt-md {
		padding: 32px 80px;
	}

	&__button {
		margin: 2rem auto;
		max-width: unset;

		@include media-query-gt-sm {
			max-width: 18rem;
		}

		@include media-query-gt-md {
			margin: 4rem auto;
		}
	}
}
