@import "src/assets/scss/mixins";
@import "src/assets/scss/variables";

.costRecap {
	margin-top: 0.6rem;
	padding: 1rem;
	padding-right: 0.4rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-radius: $border-radius;
	flex-direction: column;

	@include media-query-gt-xxs {
		flex-direction: unset;
	}

	@include media-query-gt-sm {
		margin-top: 1rem;
		padding: 1.6rem 1rem;
		padding-right: 0.6rem;
	}

	&__details {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;

		@include media-query-gt-sm {
			flex-direction: row;
			align-items: center;
		}
	}

	&__label {
		font-size: 0.9rem;
		letter-spacing: 1px;
	}

	&__price {
		min-width: 3.5rem;
		max-width: fit-content;
		flex: 1 1 100%;
		font-size: 0.9rem;
		text-align: right;
		border-radius: $border-radius;
		align-self: flex-end;

		@include media-query-gt-xxs {
			&__price {
				align-self: unset;
			}
		}

		@include media-query-gt-sm {
			min-width: 4.5rem;
			font-size: 1rem;
		}
	}

	&__discount-label {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		margin-top: 1rem;
		color: map-get($system, darkOrange);
		font-size: 16px;
		font-weight: 500;
		text-align: right;
	}

	&__discount-margin-bottom {
		@include media-query-gt-sm {
			margin-bottom: 1.5rem;
		}
	}

	&__icon-offer {
		margin-left: 5px;
		width: 3%;
	}

	&__summaryDetailsLabel {
		font-size: 14px;
		margin-top: 1rem;
		margin-bottom: 2.5rem;
	}
}

.costRecap__discount-label .costRecap__icon-tag {
	width: 1.2rem;
	height: 1.2rem;
	margin-right: 0.5rem;
}

.oneShot {
	color: map-get($brand, lightPurple);
	background-color: rgba(map-get($brand, lightPurple), 0.1);
}

.financial {
	color: map-get($system, orange);
	background-color: map-get($system, orangeBackground);
}

.recurring {
	padding: 1rem;
	padding-right: 0.4rem;
	color: map-get($system, blue);
	background-color: rgba(map-get($system, blue), 0.1);

	@include media-query-gt-sm {
		padding: 1.6rem;
		padding-right: 0.6rem;
	}
}
