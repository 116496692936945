@import "src/assets/scss/variables";
@import "src/assets/scss/mixins";

.personal-data {
	display: flex;
	flex-direction: column;

	&__row {
		padding: 2rem 1.5rem;
		padding-top: 0;
		width: 100%;
		display: grid;
		grid-template-columns: 1fr;
		grid-gap: 1rem;

		@include media-query-gt-md {
			margin: 0 auto;
			padding: 0 2rem 2rem;
			max-width: 85%;
			display: flex;
			flex: 1;
		}

		&.contactId {
			padding-top: 0;
		}
	}

	&__col {
		width: 100%;
		display: flex;
		flex-direction: column;
		margin-bottom: 1rem;
		color: map-get($system, darkGray);

		@include media-query-gt-md {
			margin-bottom: 0;
			flex: 0.33;
			&:first-child:nth-last-child(2) ~ &:last-child {
				margin-right: 1rem;
			}
		}

		.medium {
			margin: 0;
			margin-bottom: 1.5rem;
		}

		&--el {
			margin-bottom: 1rem;
			color: map-get($system, grayText);

			&:last-of-type {
				margin-bottom: 0;
			}

			.normal {
				font-size: 14px;
				font-weight: 300;
				margin-bottom: 0.5rem;
			}
		}

		&.contactId {
			display: flex;

			@include media-query-gt-md {
				display: inline-block;
			}
		}
	}

	&__divider {
		margin-bottom: 1.5rem;
		width: 100%;
		border-top: 2px dashed map-get($blacks, 100);
	}
}

.summary {
	&__wrp {
		position: relative;
		margin: 1.5rem;
		padding: 1.125rem 0.875rem 1rem 1.125rem;
		background-color: map-get($blacks, 100);
		border-radius: $border-radius;

		@include media-query-gt-md {
			margin: 2rem;
			padding: 1.5rem;
		}

		&.flex-wrp {
			display: flex;
		}
	}

	&__contentWrp {
		padding-left: 0.625rem;
		width: 100%;
		margin: auto;
		display: flex;
		flex-direction: column;
	}

	&__info {
		width: 1.25rem;
	}

	&__title {
		font-size: 0.875rem;
		color: map-get($system, #a9a9a9);
	}

	&__description {
		margin-top: 0.25rem;
		font-size: 0.875rem;
		color: map-get($system, grayText);
	}
}
