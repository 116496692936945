@import "./colors";

$breakpoints: (
	xxs: 320,
	/* very small smartphone */ xs: 480,
	/* default: smartphone */ /* smartphone landscape */ sm: 767,
	/* tablet portrait */ md: 1023,
	/* tablet landscape */ lg: 1279,
	/* desktop */ xl: 2000 /* 2K or larger */
);

$main-font: "SkyText";
$text: rgb(77, 77, 77, 1); // #4D4D4D

$header_height: 60px;
$border-radius: 4px;

$box-shadow: 0 8px 24px 0 rgba(0, 0, 0, 0.07);
$footer-box-shadow: 0 -8px 24px 0 rgba(0, 0, 0, 0.07);

$stepper-background: rgba(248, 248, 248, 0.9); // #f8f8f8e6;
